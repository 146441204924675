@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?qrm188');
  src:  url('../fonts/icomoon.eot?qrm188#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?qrm188') format('truetype'),
    url('../fonts/icomoon.woff?qrm188') format('woff'),
    url('../fonts/icomoon.svg?qrm188#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tel-02:before {
  content: "\e90a";
}
.icon-tag:before {
  content: "\e908";
}
.icon-map02:before {
  content: "\e909";
}
.icon-tel:before {
  content: "\e907";
}
.icon-arrow-02:before {
  content: "\e906";
}
.icon-arrow-in-round:before {
  content: "\e905";
}
.icon-map:before {
  content: "\e904";
}
.icon-mail:before {
  content: "\e900";
}
.icon-circle-arrow:before {
  content: "\e901";
}
.icon-blank:before {
  content: "\e902";
}
.icon-arrow:before {
  content: "\e903";
}
