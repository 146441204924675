.header {
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0;
  background: $white;
  @include tb {
    padding: 20px;
  }
  @include sp {
    padding: 10px 20px;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include tb {
      align-items: baseline;
    }
  }
  &__sp-inner {}
  &__nav {
    align-items: center;
    @include fontSize(16);
    font-weight: bold;
    max-width: 1040px;
    width: 100%;
    justify-content: space-between;
    @include pc {
      display: flex !important;
    }
    @include tb {
      display: none;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      background: $yellow-pale;
      height: 100vh;
    }
  }
  &__menu {
    display: flex;
    align-items: center;
    width: 66%;
    justify-content: space-between;
    @include tb {
      display: block;
      border: 0;
      width: 100%;
      padding: 12vh 0 0 80px;
    }
    @include sp {
      padding: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50px;
      /* page-break-after: always; */
      margin: auto;
      height: 400px
    }
  }
  &__sp-icon {
    margin: 0 30px 0 0;
    width: 20px;
  }
  &__sp-icons {
    display: flex;
    align-items: baseline;
  }
  &__menu-item {
    font-weight: bold;
    padding: 0 0.5vw;
    font-size: 15px;
    @include tb {
      margin: 0 0 25px;
    }
    @include sp {
      margin: 0 0 15px;
      @include fontSize(12);
      font-weight: normal;
    }
  }
  &__logo-link {
    margin: 0 10px 0 20px; //opacity: 0;
    //pointer-events: none;
    transition: $anime;
    width: min(100%,200px);
    &.is-visible {
      opacity: 1;
      pointer-events: all;
    }
    @include tb {
      margin: 0 10px 0 0;
    }
    @include sp {
      max-width: 120px;
    }
    &--in-pages {
      opacity: 1;
      pointer-events: all;
    }
  }
  &__logo-link-sp {
    display: none;
    opacity: 0;
    position: absolute;
    top: 20px;
    left: 20px;
    transition: $anime;
    @include tb {
      display: block;
    }
    @include sp {
      top: 15px;
      max-width: 120px;
    }
    &.is-appear {
      opacity: 1;
    }
  }
  &__sp-nav {
    display: none;
    @include tb {
      display: flex;
      align-items: center;
      position: relative;
    }
  }
  &__sp-menu {
    margin: 0 0 0 40px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include tb {
      cursor: pointer;
    }
  }
  &__sp-link-contact {
    @include fontSize(18);
    opacity: 0;
    &.is-appear {
      opacity: 1;
    }
  }
  &__sp-menu-bar-top, &__sp-menu-bar-btm {
    height: 1px;
    background: $black;
    display: block;
    width: 100%;
  }
  &__sp-menu-bar-top {
    margin-bottom: 10px;
    &.is-open {
      animation: $anime 0s forwards spNavBtnTopOpn;
    }
    &.is-close {
      animation: $anime 0s forwards spNavBtnTopCls;
    }
  }
  &__sp-menu-bar-btm {
    margin-top: 0;
    &.is-open {
      animation: $anime 0s forwards spNavBtnBtmOpn;
    }
    &.is-close {
      animation: $anime 0s forwards spNavBtnBtmCls;
    }
  }
  &__sp-copy {
    display: none;
    @include tb {
      display: block;
      @include fontSize(14);
      padding: 0 0 10px;
    }
    @include sp {
      position: absolute;
      bottom: 0;
      @include fontSize(10);
      font-weight: normal;
    }
  }
  &__contact-area {
    //position: fixed;
    top: 0;
    right: 0;
    display: flex;
    width: 280px;
    width: 32%;
    @include tb {
      display: none;
    }
  }
  &__contact-item {
    width: 50%;
    padding: 18px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include hover;
    &--form {
      background: $blue-pale;
      @include fontSize(14);
    }
    &--tel {
      background: $orange;
      @include fontSize(16);
      color: $white;
    }
  }
  &__contact-icon {
    @include fontSize(22);
  }
  &__contact-txt {
    font-weight: bold;
  }
  &__contact-icon-sp {
    @include fontSize(22);
  }
  &__tel-sp {
    @include fontSize(25);
    margin: 0 0 0 10px;
    font-weight: 500;
    line-height: 1.2;
  }
}

.footer {
  background: $black;
  padding: 30px 20px;
  margin: 140px 0 0;
  &__inner {
    width: min(100%, 1120px);
    margin: 0 auto;
  }
}

.footer-contents {
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-gap: 60px;
  margin: 0 0 60px;
  @include sp {
    display: block;
    margin: 0 0 30px;
  }
}

.footer-logo-wrap {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  @include sp {
    width: min(100%,200px);
    margin: 0 0 40px;
  }
}

.footer-nav {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  display: flex;
  gap: 40px;
  @include sp {
    display: block;
    margin: 0 0 30px;
  }
}

.footer-menu {
  &__list {
    margin: 0 0 5px;
  }
  &__link {
    color: $white;
    @include fontSize(14);
    font-weight: bold;
  }
}

.footer-play-wrap {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.footer-play-link {
  width: 35px;
  display: inline-block;
}

.footer-copy {
  font-family: $mon;
  color: $white;
  @include fontSize(10);
}