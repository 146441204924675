html {
  &.is-view-lock {
    overflow: hidden;
  }
}

body {
  /*fontは本文を基準*/
  font-family: $zen;
  @include fontSize(14);
  @include lineHeight(26, 14);
  @include letterSpacing(100);
  color: $black;
  letter-spacing: 0.5px;
}

a {
  color: $black;
}

img {
  width: 100%;
  max-width: 100%;
}

.is-hidden-pc {
  @include pc {
    display: none !important;
  }
}

.is-hidden-touch {
  @include touch {
    display: none !important;
  }
}

.is-hidden-tb {
  @include tb {
    display: none !important;
  }
}

.is-hidden-sp {
  @include sp {
    display: none !important;
  }
}

.is-sp-only {
  @include min-sp {
    display: none !important;
  }
}

.is-pc-only {
  @include sp {
    display: none !important;
  }
}

.is-vanished {
  opacity: 0;
  transition: $anime-mid; //position: relative;
  //top: 20px;
  &.is-appear {
    //top: 0;
    opacity: 1;
  }
}

[class^='anc-'] {
  margin-top: -95px;
  padding-top: 95px;
  @include tb {
    margin-top: -80px;
    padding-top: 80px;
  }
}

.lazyload {
  opacity: 0;
}

.is-lazyloaded {
  opacity: 1;
  transition: opacity .8s ease-out;
}

.txt-link {
  transition: $anime;
  text-decoration: none;
  @include hover;
}

.txt-link-line {
  border-bottom: 1px solid $black;
  padding: 0 0 5px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  @include fontSize(16);
  @include hover;
  &__icon {
    margin-left: 10px;
  }
}

.txt-link-pra {
  text-decoration: underline;
  word-break: break-all;
}

.icon-txt-right {
  margin-right: 5px;
}

.icon-txt-left {
  margin-left: 5px;
}

.main {
  margin: 88px 0 0;
  display: block;
  @include tb {
    margin: 82px 0 0;
  }
  @include sp {
    margin: 60px 0 0;
  }
}

.cont {
  padding: 0 20px;
  &__inner {
    max-width: 1000px;
    margin: 0 auto;
    padding: 60px 0 0;
    @include tb {
      padding: 40px 0 0;
    }
  }
}

.cont-ttl {
  @include fontSize(35);
  font-weight: bold;
  @include sp {
    @include fontSize(22);
    margin: 0;
  }
  &__img {
    &--support {
      max-width: 296px;
      @include sp {
        max-width: 180px;
      }
    }
  }
}

.cont-sub-ttl {
  @include fontSize(16);
  margin: 0 0 60px;
  @include sp {
    @include fontSize(14);
    margin: 0 0 30px;
  }
}

.cont-intro {
  @include fontSize(16);
  margin: 60px 0;
  @include sp {
    @include fontSize(14);
    margin: 40px 0;
  }
}

.cont-inner-sec {
  margin: 130px 0 0;
  &__ttl {
    @include fontSize(24);
    margin: 0 0 50px;
    text-align: center;
    @include sp {
      @include fontSize(22);
    }
  }
}

.tags-wrap {
  max-width: 920px;
  width: 100%;
  margin: 0 auto 70px;
}

.tags-txt {
  @include fontSize(14);
  margin: 0 0 15px;
  display: block;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
}

.tag {
  padding: 10px;
  @include fontSize(14);
  border: 1px solid $gray;
  border-radius: 5px; //max-width: 114px;
  //width: 100%;
  margin: 0 20px 20px 0;
  line-height: 1;
  text-align: center;
  @include hover;
  @include sp {
    margin: 0 10px 10px 0;
  }
}

.atcs {
  display: flex;
  margin: 0 0 80px;
  flex-wrap: wrap;
  margin: 0 -20px 0 0;
  @include sp {
    display: block;
    margin: 0 -20px 0 0;
  }
}

.atcs--sp-cols {
  @include sp {
    display: flex;
  }
}

.atc-header {
  text-align: center;
}

.atc-heading-mv {
  position: relative;
  &__copy-pos {
    position: absolute;
    z-index: 1;
    left: -3%;
    bottom: 7%;
    width: 100%;
    @include sp {
      left: -2vw;
    }
  }
  &__copy {
    color: $white;
    font-weight: bold;
    @include fontSize(24);
    line-height: 2;
    display: inline;
    box-decoration-break: clone;
    padding: 5px 10px;
    line-height: 2.4;
    &--rpt {
      background: $orange02;
    }
    &--cpn {
      background: $black;
    }
    @include sp {
      font-size: 4vw;
      padding: 0.5vw 1vw;
      line-height: 2;
    }
  }
}

.atc {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 0 0 70px;
  padding: 0 20px 0 0;
  @include sp {
    width: 100%;
    padding: 0 20px 0 0;
  }
  &--top-connel {
    width: 48%;
    padding: 15px;
    border-radius: 10px;
    background: $white;
    margin: 0;
    @include sp {
      width: 100%;
    }
  }
  &__inner {
    @include hover;
  }
  &__img-wrap {
    margin: 0 0 10px;
    position: relative;
    cursor: pointer;
    display: block;
    &--no-mov {
      cursor: auto;
      &:hover {
        opacity: 1;
      }
    }
  }
  &__new-tag {
    position: absolute;
    top: -10px;
    left: 20px;
    display: inline-block;
    text-align: center;
    background: $orange;
    color: $white;
    padding: 6px 20px 8px;
    border-radius: 20px;
    @include fontSize(16);
    font-weight: bold;
    line-height: 1;
  }
  &__icon-play {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: auto;
    opacity: 0.95;
  }
  &__date {
    display: block;
    margin: 0 0 10px;
    &--tpc {
      @include fontSize(12);
    }
  }
  &__ttl {
    @include fontSize(16);
    margin: 0 0 10px;
    font-weight: 500;
    &--top-success {
      font-weight: normal;
      @include fontSize(14);
    }
    &--topics {
      @include fontSize(14);
      font-weight: normal;
    }
    &--scs {
      @include fontSize(14);
      font-weight: normal;
    }
  }
  &__intro {
    margin: 0 0 20px;
    &--top-success {
      margin: 10px 0;
    }
  }
  &__btm {
    margin: auto 0 0;
  }
  &__cpn {
    padding: 10px 20px;
    border-left: 1px solid $black;
    border-right: 1px solid $black;
    margin: 0 0 20px;
  }
  &__btm-cols {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0;
    &--top-connel {
      margin: 0;
      @include sp {
        width: 100%;
        display: block;
      }
    }
    &--top-companies {
      @include sp {
        width: 100%;
        display: block;
      }
    }
  }
  &__hashes {
    width: 50%;
  }
  &__hash {
    margin: 0 10px 0 0;
    display: inline-block;
    @include fontSize(12);
  }
  &__dtl {
    width: 50%;
    text-align: right;
    display: block;
    &--top-connel {
      @include sp {
        width: 100%;
        margin: 10px 0 0 auto;
      }
    }
    &--top-companies {
      @include sp {
        width: 100%;
        margin: 10px 0 0 auto;
      }
    }
  }
  &__dtl-link {
    border-bottom: 1px solid $black;
    padding: 0 0 5px;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    &--top-connel {
      border-bottom: none;
    }
  }
  &__img {
    display: block;
  }
  &__thumnb-wrap {
    border-radius: 10px;
    overflow: hidden;
  }
  &-body-intro {
    border: 1px solid $black;
    padding: 20px;
    font-weight: bold;
    margin: 0 0 20px;
    @include sp {
      padding: 10px;
    }
    &__txt {
      font-weight: bold;
      @include fontSize(16);
    }
  }
}

.atc-heading-slids.slick-slider {
  margin-bottom: 0;
}

.atc--three {
  width: 33.3%;
  @include sp {
    width: 50%;
  }
}

.atc-cate {
  margin: 0 0 0 30px;
}

.atc-cols {
  display: flex;
  justify-content: space-between;
  &--top-success {
    justify-content: flex-start;
    gap: 20px;
  }
}

.atc-cols-topics {
  display: flex;
  justify-content: flex-start;
  @include fontSize(12);
  margin: 10px 0 0;
}

.atc-btm-area {
  order: 3;
  margin: 80px 0 0;
  width: 70%;
  @include tb {
    width: 100%;
    margin: 40px 0 0;
  }
  &--mb-offset {
    margin: 0 0 -100px;
  }
}

.atc-manga-wrap {
  margin: 50px auto;
  width: min(88%, 800px);
  @include sp {
    margin: 30px auto;
    width: 100%;
  }
}

.atc-manga {
  width: 100%;
  overflow: hidden;
  height: 50vw;
  position: relative;
  max-height: 800px;
  @include tb {
    width: 100%;
    height: 90vw;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, transparent 0 50%, #fff 100% 100%);
  }
  &.is-open {
    height: auto;
    max-height: none !important;
    overflow: auto;
    &:after {
      display: none;
    }
  }
}

.atc-manga-btn {
  background: $black;
  text-align: center;
  color: $white;
  padding: 10px 0;
  font-weight: bold;
  @include fontSize(18);
  cursor: pointer;
  @include sp {
    @include fontSize(16);
    padding: 5px 0;
  }
}

.icon-circle-arrow {
  &--atc-dtl {
    @include fontSize(18);
    margin: 0 0 0 5px;
  }
}

.page-nation {
  display: flex;
  justify-content: center;
}

.page-num {
  width: 30px;
  height: 30px;
  display: inline-block;
  border: 1px solid $gray;
  border-radius: 2px;
  text-align: center;
  @include fontSize(16);
  font-weight: 500;
  margin: 0 10px;
  cursor: pointer;
  &:hover {
    transition: $anime;
    background: $gray-ash;
  }
  &.is-current {
    background: $gray-ash;
  }
}

.modal {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

.modal-wrap {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 20px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  white-space: nowrap;
}

.modal-wrap::-webkit-scrollbar {
  display: none;
}

.modal-wrap:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 100%;
}

.modal-bg {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(69, 68, 68, 0.8);
  width: 100%;
  height: 100vh;
}

.modal-box {
  width: 100%;
  max-width: 880px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 0 50px;
  @include sp {
    padding: 0;
  }
  &__inner {
    background: #fff;
    text-align: left;
  }
}

.modal-close {
  position: absolute;
  right: 7%;
  top: -25px;
  @include sp {
    right: 0;
  }
  &__bar01, &__bar02 {
    display: block;
    width: 36px;
    height: 1px;
    background: $white;
    cursor: pointer;
  }
  &__bar01 {
    transform: rotate(-45deg);
  }
  &__bar02 {
    transform: rotate(45deg);
    margin: -1px 0 0;
  }
}

.modal-video-js {
  width: 100%;
  padding: 28%;
}

.modal-youtube, .modal-vimeo {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  &__inner {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.modal-link {
  color: $white;
  @include fontSize(20);
  border-bottom: 1px solid $white;
  margin: 30px 0 0;
  display: inline-block;
}

.icon-circle-arrow--modal {
  margin: 0 0 0 10px;
}

.atc-mov-wrap {
  position: relative;
  max-height: calc(100vh - 95px);
  cursor: pointer;
  &:hover {
    .atc-mov-play {
      transition: $anime;
      opacity: 0.8;
    }
  }
}

.atc-mov-play {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  width: 150px;
  height: 100px;
  @include sp {
    height: 50px;
  }
  &__img {
    width: auto;
    opacity: 0.95;
    margin: 0 auto;
    @include sp {
      width: 30%;
    }
  }
  &__txt {
    @include fontSize(16);
    font-weight: bold;
    color: $white;
    display: block;
    @include sp {
      @include fontSize(12);
    }
  }
}

.atc-cont-wrap {
  width: 90%;
  position: relative;
  left: 10%;
  @include tb {
    width: 100%;
    position: static;
    padding: 0 20px;
  }
  &--tpc {
    width: 100%;
    padding: 0 20px;
    position: static;
  }
}

.atc-cont {
  display: flex;
  margin: 0 auto 0 0;
  width: 89%;
  justify-content: space-between;
  flex-wrap: wrap;
  @include tb {
    width: 100%;
    flex-direction: column;
  }
  &--tpc {
    width: 100%;
    margin: 0;
  }
  &__header {
    &--scs {
      margin: 0;
    }
  }
  &__inner {
    border-radius: 10px;
    width: calc(100% - 330px);
    display: block;
    @include tb {
      width: 100%;
      order: 1;
      margin: 0 0 30px;
    }
    &--tpc {
      max-width: 750px;
      padding: 50px 0 0;
      margin: 0 auto;
      @include sp {
        padding: 30px 0 0;
      }
    }
  }
  &__side {
    width: 260px;
    @include tb {
      order: 2;
      width: 100%;
    }
  }
  &__hashes {
    margin: 0 0 20px;
  }
  &__hash {
    margin: 0 5px 5px 0;
    display: inline-block;
    @include fontSize(12);
    border: 1px solid $black;
    border-radius: 5px;
    padding: 0 5px;
  }
  &__ttl {
    @include fontSize(24);
    margin: 0 0 30px;
    @include sp {
      @include fontSize(22);
      margin: 0 0 20px;
      text-align: left;
    }
  }
  &__date {
    @include fontSize(20);
    margin: 0 0 10px;
    @include sp {
      @include fontSize(18);
    }
  }
  &__intro {
    @include fontSize(13);
    margin: 0 0 30px;
  }
}

.atc-sec {
  margin: 0 0 50px;
}

.atc-sec-ttl {
  @include fontSize(26);
  margin: 0 0 30px;
  font-weight: bold;
  @include sp {
    @include fontSize(22);
    margin: 0 0 20px;
  }
}

.atc-sec-txt {
  margin-bottom: 20px;
  @include fontSize(16);
}

.atc-cont-img-cols {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
  @include sp {
    display: block;
    margin: 30px 0;
  }
  &__col {
    width: 48%;
    border-radius: 10px;
    overflow: hidden;
    @include sp {
      width: 100%;
      margin: 0 0 20px;
    }
    @include sp {
      width: 100%;
    }
  }
  &__img {
    display: block;
  }
}

.atc-cont-img {
  margin: 50px 0;
  border-radius: 10px;
  overflow: hidden;
  @include sp {
    margin: 30px 0;
  }
  &__img {
    display: block;
  }
}

.atc-company-header {}

.atc-company-map {
  padding: 0 0 10px;
  border-bottom: 1px solid $black;
  margin: 0px 0 20px;
}

.atc-company-ttl {
  margin: 0 0 10px;
  font-size: 15px;
  @include fontSize(15);
  font-weight: bold;
}

.atc-company-data-side {
  position: sticky;
  top: 110px;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  @include tb {
    position: static;
    padding: 0;
    height: auto;
  }
  &__inner {
    padding: 0 0 0 min(13%, 30px);
    border-left: 1px solid $black;
    @include tb {
      padding: 0;
      border-left: 0;
    }
  }
}

.atc-company-data {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  background: $blue-pale;
  padding: 50px 50px 40px;
  border-radius: 10px;
  @include sp {
    padding: 30px 20px 20px;
  }
  &__ttl {
    @include fontSize(16);
    margin: -80px 0 0;
    @include sp {
      margin: -60px 0 0;
    }
  }
  &__ttl-inner {
    display: inline-block;
    padding: 15px 20px;
    background: $blue-pale;
    border-radius: 10px;
    @include sp {
      padding: 10px 20px 0;
      @include fontSize(14);
    }
  }
}

.atc-company-table {
  width: 100%;
  margin: 10px 0 0;
  border-collapse: separate;
  border-spacing: 0 20px;
  @include sp {
    margin: 0;
  }
  &__th {
    padding: 10px 15px;
    border-right: 1px solid $black;
    border-left: 1px solid $black;
    line-height: 1;
    width: 120px;
    vertical-align: middle;
    @include sp {
      padding: 5px 10px;
      @include fontSize(12);
      width: 100px;
    }
  }
  &__td {
    padding: 0 15px;
    vertical-align: middle;
    @include sp {
      @include fontSize(12);
    }
  }
}

.atc-company-info {
  @include sp {
    margin: 0;
  }
  &__item {
    margin: 0 0 10px;
  }
  &__heading {
    position: relative;
    @include fontSize(13);
    border-bottom: 1px solid $black;
    @include sp {
      @include fontSize(12);
    }
  }
  &__heading-ttl {
    display: inline-block;
    border-bottom: 1px solid $orange;
    position: relative;
    top: 1px;
  }
  &__body {
    display: block;
    @include fontSize(13);
    @include sp {
      @include fontSize(12);
    }
  }
}

.atc-heading {
  width: 90%;
  margin: 0 0 0 auto;
  padding: 80px 0 0;
  @include tb {
    width: 95%;
  }
  @include sp {
    width: 100%;
    padding: 40px 0 0 20px;
  }
}

.circle-btn-area {
  text-align: center;
  margin: 100px 0;
  &--top {
    margin: 50px 0;
  }
  &--left {
    text-align: left;
  }
}

.c-btn {
  webkit-animation: none !important;
  animation: none !important;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2rem;
  padding-left: 7.5rem;
  background: none;
  font-weight: 700;
  font-size: 1rem;
  text-decoration: none;
  vertical-align: top;
  &--right {
    padding-left: 0;
  }
  &__txt {
    @include fontSize(16);
    font-weight: bold; //text-decoration: underline;
    line-height: 1.5;
    border-bottom: 1px solid $black;
    &--right {
      margin-right: 5rem;
    }
  }
  &__circle {
    overflow: hidden;
    position: absolute;
    top: -3.3rem;
    right: 2rem;
    z-index: 0;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    &:before {
      -webkit-transition: -webkit-transform .5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
      transition: -webkit-transform .5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
      transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
      transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s, -webkit-transform .5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 50%;
      background: $black;
    }
    &:after {
      -webkit-transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
      transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
      -webkit-transition-property: color, opacity;
      transition-property: color, opacity;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      content: attr(data-text);
      position: absolute;
      top: 68px;
      right: -2rem;
      color: $white;
      white-space: nowrap;
      opacity: 0;
      border-bottom: 1px solid $white;
      line-height: 1.5;
    }
    &--right {
      right: -40px;
      &:after {
        right: calc(100% - 20px);
      }
    }
  }
  &__ripples {
    position: absolute;
    top: -3.3rem;
    right: 2rem;
    z-index: 0;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    &--right {
      right: -40px;
    }
    &:before {
      content: "";
      -webkit-transform: scale(1);
      transform: scale(1);
      display: block;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      width: 120px;
      height: 120px;
      margin: -60px 0 0 -60px;
      border-radius: 50%;
      background: $black;
      opacity: 0;
    }
  }
  &__icon-arw {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    -webkit-transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    -webkit-transition-property: color, -webkit-transform;
    transition-property: color, -webkit-transform;
    transition-property: transform, color;
    transition-property: transform, color, -webkit-transform;
    position: absolute; //top: calc(50% - 0.3rem);
    top: 0.6rem;
    right: 5.8rem;
    z-index: 1;
    margin-left: 0;
    color: $white;
    &--right {
      right: 1.3rem;
    }
    &:before {
      -webkit-transition: -webkit-transform .5s cubic-bezier(0.43, 0.05, 0.17, 1) 0s;
      transition: -webkit-transform .5s cubic-bezier(0.43, 0.05, 0.17, 1) 0s;
      transition: transform .5s cubic-bezier(0.43, 0.05, 0.17, 1) 0s;
      transition: transform .5s cubic-bezier(0.43, 0.05, 0.17, 1) 0s, -webkit-transform .5s cubic-bezier(0.43, 0.05, 0.17, 1) 0s;
      -webkit-transform: scale(0);
      transform: scale(0);
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 0.3rem);
      left: calc(50% - 0.3rem);
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background: currentColor;
    }
  }
  &__arw {
    display: block;
    position: relative;
    -webkit-transition: -webkit-transform .5s cubic-bezier(0.43, 0.05, 0.17, 1) 0s;
    transition: -webkit-transform .5s cubic-bezier(0.43, 0.05, 0.17, 1) 0s;
    transition: transform .5s cubic-bezier(0.43, 0.05, 0.17, 1) 0s;
    transition: transform .5s cubic-bezier(0.43, 0.05, 0.17, 1) 0s, -webkit-transform .5s cubic-bezier(0.43, 0.05, 0.17, 1) 0s;
    &:before {
      content: '';
      display: block;
      position: absolute;
      background: currentColor;
    }
    &:after {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      content: '';
      display: block;
      position: absolute;
    }
  }
  &__svg {
    width: 1rem;
    height: 1rem;
    fill: $white;
    position: relative;
  }
  &__arw-font {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 15px;
    color: $white;
    left: 0;
    right: 0;
    opacity: 1;
    display: inline-blocl;
    width: 20px;
    overflow: hidden;
    transition: $anime;
    transform: scale(1, 1);
    transform-origin: center top;
    &--right {
      transform: rotate(180deg);
      top: 32px;
    }
  }
}

.c-btn-wide {
  max-width: 690px;
  margin: 0 auto;
  border-radius: 10px;
  color: $white;
  background: $black;
  @include fontSize(19);
  text-align: center;
  padding: 20px;
  display: flex;
  align-items: center;
  @include hover;
  @include sp {
    padding: 10px;
  }
  &--orange {
    background: $orange;
    color: $white;
  }
  &__inner {
    margin: auto;
    display: flex;
    align-items: center;
  }
  &__txt {
    margin: 0 20px;
  }
}

_::-webkit-full-page-media, _:future, :root .c-btn__circle:after {
  top: 69px;
}

.c-btn:hover {
  .c-btn__circle {
    &:before {
      transform: scale(1);
    }
    &:after {
      opacity: 1;
    }
  }
  .c-btn__ripples:before {
    -webkit-animation: ripples 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
    animation: ripples 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
  }
  .c-btn__icon-arw {
    &:before {
      transform: scale(1);
    }
  }
  .c-btn__arw {
    transform: scale(0);
    &:before {
      content: '';
      display: block;
      position: absolute;
      background: currentColor;
    }
    &:after {
      box-sizing: border-box;
      content: '';
      display: block;
      position: absolute;
    }
  }
  .c-btn__arw-font {
    transition: $anime; //opacity: 0;
    width: 0;
    transform: scale(0, 1);
  }
}

.c-btn-circle {
  width: 50px;
  aspect-ratio: 1;
  background-color: $black;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include sp {
    width: 40px;
  }
  &__inner {
    background-color: $yellow-deep;
    width: 8px;
    aspect-ratio: 1;
    border-radius: 100%;
    &.is-open {
      position: relative;
      width: 15px;
      width: 15px;
      background: none;
      &:before,
      &:after {
        content: "";
        position: absolute;
        background: $yellow-deep;
        height: 2px;
        width: 15px;
        top: 0;
        bottom: 0;
        left: 0;
        bottom: 0;
        margin: auto;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
  &__inner-icon {
    color: $yellow-deep;
  }
  
}

.checkbox {
  display: none;
  +.checkbox-label {
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding-left: 50px;
    line-height: 1.5;
    @include sp {
      padding-left: 30px;
      line-height: 1;
    }
    &:before {
      content: "";
      position: absolute;
      display: block;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      top: 0;
      left: 0;
      border: 1px solid;
      border-color: $black;
      background-color: $white;
      @include sp {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.checkbox:checked+.checkbox-label:after {
  content: "";
  position: absolute;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 20px;
  height: 10px;
  top: 7px;
  left: 7px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-bottom: 3px solid;
  border-left: 3px solid;
  border-color: $black;
  @include sp {
    top: 0px;
    left: 3px;
  }
}

.commit-area {
  background: $gray-clear;
  padding: 50px 30px;
  margin: 140px 0 -140px;
  @include sp {
    margin: 80px 0 -140px;
    padding: 20px;
  }
  &--orange {
    background: $orange;
  }
  &--top {
    margin: 0;
  }
  &--top-last {
    margin: 60px 0 -140px;
  }
  &__intro {
    @include fontSize(22);
    text-align: center;
    font-weight: 500;
    margin: 0 0 40px;
    @include sp {
      @include fontSize(16);
      margin: 0 0 20px;
    }
  }
}

.cmn-btn-cols {
  display: flex;
  justify-content: space-between;
  max-width: 770px;
  margin: 0 auto;
  @include sp {
    display: block;
  }
  &__item {
    max-width: 365px;
    width: 100%;
    @include tb {
      width: 48%;
    }
  }
}

.cmn-btn {
  border-radius: 10px;
  border: 1px solid $gray;
  padding: 28px 10px;
  display: flex;
  align-items: center;
  background: $white;
  box-sizing: border-box;
  @include sp {
    width: 100%;
    padding: 10px;
    margin: 0 0 20px;
    max-width: 100%;
    &:last-of-type {
      margin: 0;
    }
  }
  &--orange {
    background: $orange;
    border: 0;
    color: $white;
  }
  &--yellow {
    background: $yellow;
    border: 0;
    color: $black;
  }
  &--red {
    background: $red;
    border: 0;
    color: $white;
  }
  &__txt {
    @include fontSize(18);
    margin: auto;
    font-weight: 500;
    &--form {
      margin: 0 0 0 10px;
    }
    &--white {
      color: $white;
    }
  }
  &__arrow {
    @include fontSize(20);
  }
  &--form {
    @include fontSize(16);
    padding: 10px;
    justify-content: center;
    max-width: 360px;
    margin: 0 auto;
  }
  &--black {
    background: $black;
    border: 1px solid $black;
  }
}

.cmn-btn-low {
  border-radius: 10px;
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &--yellow {
    background-color: $yellow;
  }
  &__txt {
    @include fontSize(16);
    font-weight: bold;
    margin: 0 auto 0 20px;
  }
  &__arrow {
    border-radius: 100%;
    background-color: $black;
    color: $white;
    padding: 2px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
  }
}

.commit-bottom {
  max-width: 770px;
  margin: 40px auto 0;
  text-align: center;
  &__term-form {
    @include fontSize(22);
    text-align: center;
    margin: 0 0 40px;
    padding: 0 0 10px;
    border-bottom: 1px solid $gray;
    font-weight: 500;
    &--white {
      border-bottom: 1px solid $white;
      color: $white;
    }
  }
  &__lead {
    @include fontSize(16);
    text-align: center;
    margin: 0 0 10px;
    font-weight: 500;
    &--white {
      color: $white;
    }
  }
  &__tyu {
    @include fontSize(14);
    margin: 20px 0 40px;
    text-align: left;
    letter-spacing: 0.5px;
  }
  &__term-tel {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin: 0 0 10px;
  }
  &__tel-icon {
    @include fontSize(24);
  }
  &__tel-txt {
    @include fontSize(20);
    margin: 0 0 0 10px;
    font-weight: 500;
    &--white {
      color: $white;
    }
  }
  &__lead {
    @include fontSize(16);
    @include sp {
      text-align: left;
    }
  }
  &__tel-num {
    @include fontSize(46);
    font-weight: 500;
    margin: 0 0 0 20px;
    @include sp {
      @include fontSize(34);
    }
  }
  &__tel-link {
    @include fontSize(30);
    @include sp {
      @include fontSize(22);
    }
  }
  &__reception {
    @include fontSize(14);
    font-weight: 500;
    @include sp {
      text-align: left;
    }
  }
}

.cate-tabs {
  margin: 0 0 50px;
  display: flex;
}

.cate-tab {
  padding: 0 15px 0;
  border-right: 1px solid $black;
  line-height: 1;
  color: $gray;
  @include fontSize(12);
  cursor: pointer;
  @include hover;
  @include sp {
    padding: 0 12px 0;
  }
  &.is-current {
    color: $black;
  }
  &:first-of-type {
    border-left: 1px solid $black;
  }
}

.atc-slids-dots-wrap {
  width: 90%;
  margin: 20px 0 40px auto;
  @include tb {
    width: 95%;
  }
  @include sp {
    width: 100%;
    padding: 0 0 0 20px;
    margin: 0 0 20px auto;
  }
}

.my-dotsatc-slids-dots {
  position: static;
  text-align: left;
}

.slick-dots li {
  @include sp {
    height: 10px;
    width: 10px;
    margin: 0 9px;
  }
}

.my-dotsatc-slids-dots li {
  height: 10px;
  width: 10px;
  margin: 0 40px 0 0;
  &:last-child {
    button {
      &:after {
        background: none;
      }
    }
    &.slick-active button {
      top: 0;
      @include sp {
        top: -1px;
      }
    }
  }
}

.my-dotsatc-slids-dots li button {
  background: $black;
  border-radius: 15px;
  height: 5px;
  width: 5px;
  @include sp {
    padding: 4px;
    height: 10px;
    width: 10px;
    position: relative;
    top: -1px;
  }
  &:before {
    content: "";
    opacity: 1;
    width: 10px;
    height: 10px;
  }
  &:after {
    content: "";
    height: 2px;
    width: 50px;
    background: $black;
    position: relative;
    z-index: -1;
    top: -1px;
    display: block;
    @include sp {
      top: 0;
    }
  }
}

.my-dotsatc-slids-dots li.slick-active button {
  width: 16px;
  height: 16px;
  position: relative;
  left: -3px;
  top: -1px;
  border: 1px solid $orange02;
  background: none;
  &:before {
    opacity: 1;
    border-radius: 10px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    background: $orange;
  }
  &:after {
    top: 0;
  }
}

.slick-dots>li:first-child:last-child {
  display: none;
}

.plc-cont {
  margin: 100px 0 0;
  @include sp {
    margin: 50px 0 0;
  }
}

.plc-intro {
  margin: 0 0 40px;
}

.plc-sec {
  margin: 0 0 40px;
  &__ttl {
    @include fontSize(16);
    font-weight: bold;
  }
  &__pra {
    margin: 0 0 20px;
    &--no-mg {
      margin: 0;
    }
  }
  &__lists {
    margin: 0 0 20px;
  }
  &__list-item {
    padding-left: 1rem;
    text-indent: -1rem;
  }
  &__table {
    margin: 0 0 20px;
  }
  &__table-th {
    width: 100px;
    text-align: left;
  }
}

.icon-mail {
  &--white {
    color: $white;
  }
}

.icon-tel {
  &--white {
    color: $white;
  }
}

.atc-cpn-cnt-area {
  &__intro {
    text-align: center;
    @include fontSize(16);
    margin: 0 0 30px;
  }
}

.atc-cpn-cnt-btn {
  max-width: 690px;
  margin: 0 auto;
  border-radius: 10px;
  color: $white;
  background: $black;
  @include fontSize(19);
  text-align: center;
  padding: 20px;
  display: flex;
  align-items: center;
  @include hover;
  @include sp {
    padding: 10px;
  }
  &__inner {
    margin: auto;
    display: flex;
    align-items: center;
  }
  &__txt {
    margin: 0 20px;
  }
  &--mid {
    max-width: 350px;
  }
}

.atc-side-ttl {
  text-align: center;
  @include fontSize(20);
  padding: 5px 10px;
  margin: 0 0 10px;
  font-weight: 500;
  color: $white;
  &--rpt {
    background: $orange;
  }
  &--cpn {
    background: $black;
  }
}

.atc-back-btn-wrap {
  margin: 0 0 60px;
}

.star {
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  background: $orange;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.atc-status-wrap {
  border: 1px solid $black;
  padding: 20px;
  margin: 0 0 40px;
  @include sp {
    padding: 10px;
  }
}

.atc-status {
  display: table;
  flex-wrap: wrap;
  margin: 0 auto;
  @include sp {
    display: block;
  }
  &__item {
    display: table-row;
    @include sp {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__title {
    @include fontSize(18);
    font-weight: bold;
    padding: 0 20px 0 0;
    display: table-cell;
    @include sp {
      @include fontSize(16);
      padding: 0 10px 0 0;
      display: block;
      width: fit-content;
      max-width: 50%;
    }
  }
  &__stars {
    display: table-cell;
    vertical-align: middle;
    padding: 7px 0 0;
    @include sp {
      width: fit-content;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0;
    }
  }
  &__spacer {
    width: 20%;
    display: table-cell;
    @include sp {
      display: block;
      width: 100%;
    }
  }
}

.atc-quot {
  margin: 0 0 10px;
  &__img {
    width: 12px;
  }
}

.atc-side-desc {
  font-weight: bold;
  @include fontSize(20);
  @include sp {
    @include fontSize(18);
  }
}

.link-wrap {
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
}

.link-prime {
  display: inline-flex;
  align-items: flex-end;
  &__txt {
    @include fontSize(20);
    margin: 0 20px 0 0;
    @include sp {
      @include fontSize(14);
    }
    &.bdr-white {
      border-bottom: 2px solid $white;
    }
    &.bdr-black {
      border-bottom: 2px solid $black;
    }
  }
  &__txt-bold {
    font-weight: bold;
  }
}

.atc-tag {
  border-radius: 20px;
  padding: 0 12px;
  display: inline-block;
  &.is-gray {
    background-color: $gray-thin;
  }
  &.is-white {
    background-color: $white;
  }
  &__date {
    padding: 0 8px 0 0;
    margin: 0 8px 0 0;
    font-family: $mon;
    @include fontSize(14);
    border-right: 1px solid $gray-light;
  }
  &__map {
    margin: 0 2px 0 0;
    color: $orange03;
    @include fontSize(16);
    vertical-align: middle;
  }
  &__tag {
    margin: 0 2px 0 0;
    color: $orange03;
    @include fontSize(12);
  }
  &__pref {
    @include fontSize(12);
  }
}

.btn-square-rd {
  &--yellow
  &__txt {}  
}

.btn-icon-circle {
  &__arrow {}
}

